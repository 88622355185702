<template>
  <div id="exportDialog">
    <el-dialog
      title="导出报表"
      :visible.sync="show"
      width="500px"
      @closed="reset"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="时间范围" prop="time">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="停车场" prop="parkingLotId">
          <el-select v-model="form.parkingLotId" placeholder="请选择停车场">
            <el-option
              v-for="item in parkingLotList"
              :key="item.parkingLotId"
              :label="item.parkingLotName"
              :value="item.parkingLotId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" prop="recordType">
          <el-radio-group v-model="form.recordType">
            <el-radio
              :label="item.value"
              v-for="item of select_recordType"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="导出类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio
              :label="0"
              :disabled="!buttonList.includes('stopOrderList-export-excel')"
              >Excel</el-radio
            >
            <el-radio
              :label="1"
              :disabled="!buttonList.includes('stopOrderList-export-pdf')"
              >PDF</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      form: {
        time: "",
        parkingLotId: "",
        type: "",
        recordType: "",
      },
      rules: {
        time: [{ required: true, validator: this.checkTime, trigger: "blur" }],
        parkingLotId: [
          { required: true, message: "请选择停车场", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择导出类型", trigger: "change" },
        ],
        recordType: [
          { required: true, message: "请选择订单类型", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    checkTime(rule, value, callback) {
      for (let item of value) {
        if (item === "") {
          callback(new Error("请选择时间范围"));
          return;
        }
      }
      callback();
    },
    reset() {
      this.$refs["form"].resetFields();
    },
    confirm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let startTime = this.form.time[0];
          let endTime = this.form.time[1];

          try {
            await this.$http.post(
              "/order/report/export/merchant",
              {
                type: this.form.type,
                startTime,
                endTime,
                parkingLotId: this.form.parkingLotId,
                recordType: this.form.recordType,
              },
              {
                responseType: "blob",
                timeout: 0,
              }
            );
            this.loading = false;
          } catch {
            this.$message.error("导出失败");
            this.loading = false;
          }
        }
      });
    },
    handleShow() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
#exportDialog {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
